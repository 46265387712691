import Chat from "./components/chat";
import { useParams } from "react-router-dom";
import { useThread } from "./components/hooks";
import { orderBy } from "natural-orderby";

export default function ThreadByid() {
  const { id = "" } = useParams();
  const { data: thread, isValidating } = useThread(id);

  return (
    <div className="p-[20px] md:p-10 min-h-screen flex flex-col justify-between items-center">
      <div className="w-full mt-10 mb-5">
        <Chat
          threadId={id}
          threadMessages={orderBy(
            thread?.messages || [],
            ({ timestamp }) => timestamp
          )}
          loading={isValidating && !thread}
        />
      </div>
      <p className="text-gray-500 mt-auto text-[10px] md:text-xs">
        ArkeoAi may produce inaccurate information about people, places, or
        facts.
      </p>
    </div>
  );
}
