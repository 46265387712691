import { Divider, Input, Modal, message } from "antd";
import * as EmailValidator from "email-validator";
import apiService from "../../services/api-service";
import { useState } from "react";

interface Props {
  open: boolean;
  onCancel: () => void;
}

export default function InviteModal({ open, onCancel }: Props) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleInvite = async () => {
    if (!EmailValidator.validate(email)) {
      return message.error("Invalid email.");
    }
    if (name.replaceAll(" ", "").length === 0) {
      return message.error("Invalid name.");
    }
    setLoading(true);
    const { errors } = await apiService.sendInvite(email, name);
    if (errors) {
      message.error("An account with the given email already exists.");
    } else {
      message.success("Invite successfully sent.");
    }
    setLoading(false);
  };
  return (
    <Modal
      title={"Invite Member"}
      onOk={handleInvite}
      onCancel={onCancel}
      okText={"Send Invites"}
      okButtonProps={{ style: { backgroundColor: "#1890ff" } }}
      open={open}
      confirmLoading={loading}
    >
      <Divider className="mt-1 mb-6" />
      <p className="text-gray-700 text-xs mb-2">Name:</p>
      <Input
        placeholder="Name"
        className="mb-2"
        onChange={({ target: { value } }) => setName(value)}
        status={
          name.length > 0 && name.replaceAll(" ", "").length === 0
            ? "error"
            : undefined
        }
      />
      <p className="text-gray-700 text-xs mb-2">Email:</p>
      <Input
        placeholder="sample@email.com"
        className="mb-2"
        onChange={({ target: { value } }) => setEmail(value)}
        status={
          email.length > 0 && !EmailValidator.validate(email)
            ? "error"
            : undefined
        }
      />
    </Modal>
  );
}
