import { Button, Drawer } from "antd";
import {
  CloseOutlined,
  SettingOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Emitter from "../../emitter";

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
  links: {
    path: string;
    icon: JSX.Element;
    name: string;
  }[];
}

function MobileSideNav({ setVisible, visible, links }: Props) {
  const { pathname: currentPathname } = useLocation();
  const navigate = useNavigate();

  return (
    <Drawer
      placement={"left"}
      style={{
        flex: 1,
        backgroundColor: "#0AB1FF",
        padding: 20,
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
      }}
      className="flex justify-center"
      onClose={() => setVisible(false)}
      open={visible}
      width={300}
      closeIcon={<CloseOutlined className="text-white" />}
      styles={{ body: { padding: 0 } }}
    >
      <div className="flex flex-col h-full p-2">
        <div className="flex justify-center">
          <img src="/logo.png" alt="ArkeoAi" style={{ width: 70 }} />
        </div>
        <Button
          className="w-[184px] self-center bg-[#EAEA48] font-[700] mt-5 text-[#222B45] text-[16px] font-sans leading-[20px] px-[8px] py-[2px] border-[0]"
          onClick={() => {
            navigate("/");
            Emitter.emit("NEW_THREAD", {});
          }}
        >
          NEW THREAD
        </Button>
        <div className="flex flex-col items-start mt-5 ">
          {links.map(({ path, icon, name }) => (
            <Button
              key={name}
              className={`flex items-center text-base w-full mb-[12px] text-[23px] font-[500]  px-[24px] py-[16px] leading-[28px] w-[282px] border-[0] h-[60px] font-sans
           ${path === currentPathname ? "bg-[#34bcff]" : ""}`}
              type="primary"
              icon={icon}
              onClick={() => {
                navigate(path);
                setVisible(false);
              }}
            >
              <Link to={path}>{name}</Link>
            </Button>
          ))}
        </div>
        <Button
          className={`flex items-center text-base w-full text-[23px] font-[500]  px-[24px] py-[16px] leading-[28px] w-[282px] border-[0] h-[60px] font-sans mt-auto`}
          type="primary"
          icon={<SettingOutlined className="text-[23px] mr-[4px]" />}
          onClick={() => {
            navigate("/settings");
            setVisible(false);
          }}
        >
          <p>Settings</p>
          <ArrowRightOutlined className="mt-1 text-[20px] ml-auto" />
        </Button>
      </div>
      <style>{`
        .ant-drawer-close {
          position: absolute;
          right: 5px;
        }
      `}</style>
    </Drawer>
  );
}

export default MobileSideNav;
