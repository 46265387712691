import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { Icon } from "@mui/material";
import { useUser } from "./hooks";

export default function ProfileFloatingButton() {
  const { data: user } = useUser();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="flex absolute top-3 right-5">
      <Button
        onClick={handleClick}
        style={{
          borderRadius: 40,
          borderWidth: 0,
          width: 40,
          height: 40,
        }}
        icon={
          <Icon
            component={
              user
                ? () => (
                    <img
                      src={
                        user.profile?.profilePicture ||
                        "/profile-default-picture.png"
                      }
                      alt={"profile"}
                      style={{ borderRadius: 40 }}
                    />
                  )
                : () => null
            }
          />
        }
      />
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("/settings");
            handleClose();
          }}
        >
          Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            Auth.signOut();
            navigate("/");
            handleClose();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </div>
  );
}
