import { Button, Layout } from "antd";
import {
  SettingOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Emitter from "../../emitter";
import { setMobile } from "../../redux/actions";
import { useDispatch } from "react-redux";

const { Sider } = Layout;

export default function SideNav({
  links,
  mobile,
}: {
  mobile: boolean;
  links: {
    path: string;
    icon: JSX.Element;
    name: string;
  }[];
}) {
  const { pathname: currentPathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <Sider
      style={{
        flex: 1,
        overflow: "auto",
        minHeight: "100vh",
        backgroundColor: "#0AB1FF",
        position: "fixed",
        padding: 20,
        paddingLeft: 10,
        paddingRight: 10,
        display: "flex",
      }}
      theme="light"
      width={300}
      breakpoint="md"
      onBreakpoint={(broken) => {
        dispatch(setMobile(broken));
      }}
      className="flex justify-center w-full h-full"
    >
      <div className="flex flex-col h-full w-[300px] p-2">
        <div className="flex justify-center">
          <img src="/logo.png" alt="ArkeoAi" style={{ width: 70 }} />
        </div>
        <Button
          className="w-[184px] self-center bg-[#EAEA48] font-[700] mt-5 text-[#222B45] text-[16px] font-sans leading-[20px] px-[8px] py-[2px] border-[0]"
          onClick={() => {
            navigate("/");
            Emitter.emit("NEW_THREAD", {});
          }}
        >
          NEW THREAD
        </Button>
        <div className="flex flex-col items-start mt-5 ">
          {links.map(({ path, icon, name }) => (
            <Button
              key={name}
              className={`flex items-center text-base w-full mb-[12px] text-[23px] font-[500]  px-[24px] py-[16px] leading-[28px] w-[282px] border-[0] h-[60px] font-sans
                 ${path === currentPathname ? "bg-[#34bcff]" : ""}`}
              type="primary"
              icon={icon}
              onClick={() => navigate(path)}
            >
              <Link to={path}>{name}</Link>
            </Button>
          ))}
        </div>
        <Button
          className={`flex items-center text-base w-full text-[23px] font-[500]  px-[24px] py-[16px] leading-[28px] w-[282px] border-[0] h-[60px] font-sans mt-auto`}
          type="primary"
          icon={<SettingOutlined className="text-[23px] mr-[4px]" />}
          onClick={() => navigate("/settings")}
        >
          <p>Settings</p>
          <ArrowRightOutlined className="mt-1 text-[20px] ml-auto" />
        </Button>
      </div>
    </Sider>
  );
}
