import Axios, { AxiosResponse } from "axios";
import qs from "qs";
import config from "./config";
import { getIdToken } from "./services/api-service";
import { env } from "process";

// Needed because we don't throw errors in our API funcs
// but SWR needs the method to throw an error so it
// knows when an error has occurred vs success
export async function wrapSWR<T, Q>(
  prom: () => Promise<{ data?: T; errors?: any }>,
  resolve: (data: T) => Q
) {
  const { data, errors } = await prom();
  if (errors || !data) {
    throw errors;
  }
  return resolve(data);
}

export default function endpoint() {
  // get dev or prod config based on env
  const instance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  return async function <T, E = any>(
    method: string,
    url: string,
    data: any = {},
    options: any = {}
  ): Promise<
    | { data: T; errors: undefined; response: AxiosResponse }
    | { data: undefined; errors: E; response: AxiosResponse }
  > {
    const token = await getIdToken();

    const contentType =
      typeof window !== "undefined" && data instanceof FormData
        ? "multipart/form-data"
        : "application/json";

    try {
      const response = await instance({
        method: method.toLowerCase(),
        url,
        ...(method.toLowerCase() === "get" ? { params: data } : { data }),
        headers: {
          Authorization: token,
          "Content-Type": contentType,
        },
        paramsSerializer: () => {
          return qs.stringify(data);
        },
        ...options,
      });

      return { data: response.data, errors: undefined, response };
    } catch (e: any) {
      return {
        data: undefined,
        errors: e.response?.data,
        response: e.response,
      };
    }
  };
}
