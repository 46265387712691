import { Avatar, Spin, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import {
  RepeatOutlined,
  SentimentSatisfiedOutlined,
  ContentCopyTwoTone,
  SentimentDissatisfiedOutlined,
} from "@mui/icons-material";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { Message } from "../../types";
import Emitter from "../../emitter";
import ArkeoAiChatService from "../../services/arkeo-chat-service";
import { useMobile } from "../../helpers/helpers";

export default function Chat({
  threadId = "",
  threadMessages,
  loading = false,
}: {
  threadId?: string;
  threadMessages?: Message[];
  loading?: boolean;
}) {
  const [message, setMessage] = useState<string>("");
  const [messages, setMessages] = useState<Message[]>([]);
  const [thread, setThread] = useState<string>(threadId);
  const [chatApi] = useState(new ArkeoAiChatService());
  const mobile = useMobile();

  useEffect(() => {
    Emitter.on("NEW_THREAD", () => {
      setMessages([]);
      setThread("");
    });
    chatApi.connect();
    return () => {
      if (
        chatApi.webSocket &&
        chatApi.webSocket.readyState === WebSocket.OPEN
      ) {
        chatApi.webSocket.close();
      }
    };
  }, [chatApi]);

  useEffect(() => {
    setMessages(threadMessages || []);
  }, [threadMessages]);

  useEffect(() => {
    chatApi.setWebSocketCallback((response: Message) => {
      if (
        response === null ||
        response.message == null ||
        // Endpoint request timed out
        (!response.threadID && !!response.connectionId)
      ) {
        // if message is null it means the stream of message is over
        return;
      }
      if (thread === "" && response.threadID !== undefined) {
        setThread(response.threadID);
      }

      setMessages((prevMessages) => {
        const lastIndex = prevMessages.length - 1;

        // If there are no previous messages
        if (lastIndex < 0) {
          return [{ type: "arkeoai", message: response.message }];
        }

        const lastMessage = prevMessages[lastIndex];

        // if the last message is the "loading indicator"
        if (lastMessage.message === "...") {
          return [
            ...prevMessages.slice(0, lastIndex),
            { ...lastMessage, message: response.message },
          ];
        }

        const updatedMessage = {
          ...lastMessage,
          message: lastMessage.message + response.message,
        };

        // Return a new array with all previous messages except the last one, followed by the updated message
        return [...prevMessages.slice(0, lastIndex), updatedMessage];
      });
    });
  }, [chatApi, thread]);

  const handleSend = async () => {
    setMessage("");
    const threadId = thread !== "" ? thread : "new";
    if (message.trim()) {
      // Add user's message to the state
      setMessages([
        ...messages,
        { type: "user", message: message },
        { type: "arkeoai", message: "..." },
      ]);
      chatApi.sendChatMessage(message, threadId);
    }
  };

  return (
    <div>
      <div className="chat-messages w-full">
        {loading ? (
          <div className="flex justify-center my-5">
            <Spin />
          </div>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`${
                msg.type === "user" ? "bg-gray-200" : "bg-white"
              } flex flex-row items-center p-2 rounded-lg justify-between mt-2 w-full`}
            >
              <div className="flex flex-row items-center ">
                <Avatar
                  className="mr-5 self-start"
                  src={
                    msg.type === "user"
                      ? "https://www.w3schools.com/howto/img_avatar.png"
                      : "/arkeo-logo.png"
                  }
                  size={mobile ? 40 : 60}
                />
                <ReactMarkdown
                  className={`flex-1 text-[12px] xs:text-sm font-normal ${
                    msg.type === "arkeoai" ? "text-gray-700" : "text-black"
                  } leading-5 md:leading-7 whitespace-pre-wrap`}
                  remarkPlugins={[gfm]}
                  children={msg.message.replace(/\n/g, "\n &nbsp;")}
                />
              </div>
              <div className="self-start">
                {msg.type === "user" ? (
                  <Tooltip title="Regenerate">
                    <RepeatOutlined className="text-[24px] text-gray-700 ml-5 cursor-pointer" />
                  </Tooltip>
                ) : (
                  <div className="flex flex-col md:flex-row max-md:ml-5">
                    <Tooltip title="Copy Output">
                      <ContentCopyTwoTone className="text-[24px] text-gray-700 md:ml-5 cursor-pointer" />
                    </Tooltip>
                    <Tooltip title="Useful Output">
                      <SentimentSatisfiedOutlined className="text-[24px] text-gray-700 max-md:mt-3 md:ml-5 cursor-pointer" />
                    </Tooltip>
                    <Tooltip title="Unhelpful Output">
                      <SentimentDissatisfiedOutlined className="text-[24px] text-gray-700 max-md:mt-3 md:ml-5 cursor-pointer" />{" "}
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          ))
        )}
      </div>
      <div className="flex flex-row justify-center">
        <TextArea
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Send a message"
          autoSize={{ minRows: 3, maxRows: 6 }}
          className="flex mt-5 ml-5 w-[500px] border-[#F6F6F6]"
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          style={{
            boxShadow: "4px 4px 12px 0px #8F9BB314",
            paddingRight: 35,
          }}
        />
        <div className="relative -left-[35px] top-[5px]">
          <SendOutlined
            onClick={handleSend}
            className="text-[22px] text-[#0AB1FF] m-6 ml-2 cursor-pointer"
          />
        </div>
      </div>
    </div>
  );
}
