import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";

export default function ContextCard() {
  return (
    <div className="p-5 flex flex-col bg-white rounded-[10px] flex-1">
      <div className="flex flex-row justify-between items-center">
        <p className="text-gray-400 text-[24px] font-bold">Edit Context</p>

        <Button
          className="bg-[#e6eaf7] border-[#5b7efe] font-bold text-md text-[#3763ff]"
          onClick={() => {}}
        >
          UPLOAD CSV
        </Button>
      </div>
      <p className="mt-5 text-gray-600">
        Download and complete the templated CSV for your initial company
        context. Once completed upload the CSV to settings.
      </p>

      <div className="flex flex-row mt-5">
        <div className="flex items-center justify-between p-5 bg-[#a3e735] rounded-2xl">
          <UploadOutlined className="text-white text-xl" />
          <p className="font-bold ml-2 text-white text-xl">CSV</p>
        </div>
        <div className="flex flex-col pl-3">
          <p className="text-blue-600 font-bold">Import Complete</p>
          <p className="text-gray-600 text-xs mb-2">
            Completed on September 1, 2023 at 8:46am PST
          </p>
          <div className="flex flex-row flex-wrap items-center">
            <p className="flex flex-row mr-10 text-gray-600 text-[10px] md:text-sm">
              <span className="font-bold mr-1">Type: </span>
              <span>CSV</span>
            </p>
            <p className="flex flex-row text-gray-600 text-[10px] md:text-sm">
              <span className="font-bold mr-1 whitespace-nowrap">
                Doc Name:{" "}
              </span>
              <span>ArkeoA Initial Global Context SE.csv</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
