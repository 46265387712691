import apiService from "../../services/api-service";
import useSWR from "swr";
import { Message } from "../../types";
import { wrapSWR } from "../../endpoint";

export function useThreads() {
  return useSWR<
    Array<{
      id: string;
      createdAt: string;
      title: string;
      messages: Array<Message>;
    }>
  >("/threads", () =>
    wrapSWR(
      () => apiService.getThreads(),
      (data) => data
    )
  );
}
export function useThread(id: string) {
  return useSWR<{ id: string; messages: Array<Message> }>(`/thread/${id}`, () =>
    wrapSWR(
      () => apiService.getThreadInfo(id),
      (data) => data
    )
  );
}
export function useUser() {
  return useSWR("/user", () => apiService.getCurrentUserInfo());
}
