import { useState } from "react";
import { useSelector } from "react-redux";
import { StoreState } from "../types";

export function safeWindow() {
  return typeof window !== "undefined" ? window : undefined;
}

export function useModalToggle() {
  const [open, setOpen] = useState(false);

  function show() {
    setOpen(true);
  }

  function close() {
    setOpen(false);
  }

  return {
    props: {
      open,
      onCancel: close,
      onClose: close,
    },
    show,
    close,
  };
}

export function useMobile() {
  const mobile = useSelector((state: StoreState) => state.ui.mobile);
  return mobile;
}
