import { Button, Divider, Input, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import InviteModal from "../components/invite-modal";
import { useMobile, useModalToggle } from "../../helpers/helpers";
import { useEffect, useState } from "react";
import { User } from "../../types";
import api from "../../services/api-service";
import moment from "moment";

export default function InviteCard() {
  const inviteModal = useModalToggle();
  const [users, setUsers] = useState<User[]>();
  const [search, setSearch] = useState("");
  const isMobile = useMobile();

  const fetchUsers = () => {
    const res = api.getUsers();
    res.then(({ data }) => {
      setUsers(data || []);
    });
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (_: any, record: User) =>
        capitalizeFirstLetter(record.role || ""),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: any, record: User) =>
        capitalizeFirstLetter(record.status || ""),
    },
    {
      title: "Last Active",
      dataIndex: "lastActive",
      key: "lastActive",
      render: (_: any, record: User) =>
        record.lastActive ? (
          <p>{moment(record.lastActive).format("MMM Do, YYYY hh:mm a")}</p>
        ) : null,
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (_: any, record: User) =>
        record.status === "pending" ? (
          <div className="w-[100px] flex flex-row flex-wrap">
            <Button
              type="link"
              onClick={async () => {
                await api.sendInvite(record.email, record.name);
                fetchUsers();
              }}
            >
              Resend
            </Button>
            <Button
              type="link"
              onClick={async () => {
                await api.deactivateUser(record.id);
                fetchUsers();
              }}
            >
              Cancel
            </Button>
          </div>
        ) : ["active", "confirmed"].includes(record.status || "") ? (
          <Button
            type="link"
            onClick={async () => {
              await api.deactivateUser(record.id);
              fetchUsers();
            }}
          >
            Deactivate
          </Button>
        ) : null,
    },
  ];
  return (
    <div className="p-5 flex flex-col bg-white rounded-[10px] flex-1">
      <div className="flex flex-row justify-between items-center">
        <p className="text-gray-400 text-[24px] font-bold">Your People</p>
        <Button
          className="bg-[#ff3d71] border-[#ff3d71] font-bold text-md text-white"
          onClick={inviteModal.show}
        >
          INVITE
        </Button>
      </div>
      <div className="flex flex-row justify-between items-center mt-5">
        <p className="text-gray-700 font-bold">Active Team Members</p>
        <Input
          addonAfter={<SearchOutlined />}
          placeholder="Search Members"
          value={search}
          onChange={({ target: { value } }) => setSearch(value)}
          allowClear
          className="w-[200px]"
        />
      </div>
      {isMobile ? (
        <div>
          {users
            ?.filter(({ name }) =>
              name.toLowerCase().includes(search.toLowerCase())
            )
            .map(({ id, name, email, role, status }) => (
              <div className="flex flex-row flex-wrap justify-between items-center">
                <Divider />
                <div className="flex flex-col mr-5">
                  <p className="text-gray-700 text-sm font-bold">{name}</p>
                  <p className="mt-1 text-gray-700 text-xs">{email}</p>
                  <p className="mt-1 text-gray-700 text-sm">
                    Role: <span className="font-bold">{role}</span>
                  </p>
                  <p className="mt-1 text-gray-700 text-xs">Status: {status}</p>
                </div>
                <div className="flex ml-auto self-start">
                  {status === "pending" ? (
                    <div className="flex flex-row flex-wrap">
                      <Button
                        type="link"
                        onClick={async () => {
                          await api.sendInvite(email, name);
                          fetchUsers();
                        }}
                        className="text-xs"
                      >
                        Resend
                      </Button>
                      <Button
                        type="link"
                        onClick={async () => {
                          await api.deactivateUser(id);
                          fetchUsers();
                        }}
                        className="text-xs"
                      >
                        Cancel
                      </Button>
                    </div>
                  ) : ["active", "confirmed"].includes(status || "") ? (
                    <Button
                      type="link"
                      onClick={async () => {
                        await api.deactivateUser(id);
                        fetchUsers();
                      }}
                      className="text-xs"
                    >
                      Deactivate
                    </Button>
                  ) : null}
                </div>
              </div>
            ))}
        </div>
      ) : (
        <Table
          dataSource={users?.filter(({ name }) =>
            name.toLowerCase().includes(search.toLowerCase())
          )}
          columns={columns}
          className="mt-5"
          rowKey={(record) => record.email}
        />
      )}
      <InviteModal {...inviteModal.props} />
    </div>
  );
}
