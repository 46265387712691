import { Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useThreads } from "./components/hooks";
import { orderBy } from "natural-orderby";
import ThreadItem from "./components/thread-item";

export default function Threads() {
  const { data: threads, isValidating } = useThreads();
  const [search, setSearch] = useState("");

  return (
    <div className="p-[20px] md:p-10">
      <Input
        addonBefore={<SearchOutlined />}
        placeholder="Search"
        allowClear
        className="md:w-3/5 border-[#F6F6F6]"
        onChange={(text) => setSearch(text.target.value)}
      />
      <p className="text-gray-400 text-xl md:text-[26px] font-bold mt-5">
        Threads
      </p>
      {isValidating && !threads ? (
        <div className="flex justify-center mt-20">
          <Spin />
        </div>
      ) : (
        orderBy(threads || [], ({ createdAt }) => createdAt, "desc")
          ?.filter(
            ({ title, messages }) =>
              messages.length > 0 &&
              title.toLowerCase().includes(search.toLowerCase())
          )
          .map(({ title, id, messages, createdAt }) => {
            const orderedMessages = orderBy(
              messages,
              ({ timestamp }) => timestamp
            );
            return (
              <ThreadItem
                key={id}
                id={id}
                createdAt={createdAt}
                title={title}
                description={orderedMessages.pop()?.message}
              />
            );
          })
      )}
    </div>
  );
}
