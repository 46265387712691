import { combineReducers } from "redux";
import uiReducer from "./reducers";
import { configureStore } from "@reduxjs/toolkit";

const appReducer = combineReducers({
  ui: uiReducer,
});

const store = configureStore({ reducer: appReducer });

export default store;
