import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Button, Divider } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { MoreOutlined } from "@ant-design/icons";

export default function ThreadItem({
  id,
  title,
  createdAt,
  description,
}: {
  id: string;
  title: string;
  createdAt: string;
  description?: string;
}) {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div key={id} className="flex flex-col bg-white mt-4 rounded-b-lg">
      <div className="pl-5 py-4 pr-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-col md:flex-row justify-between w-full">
            <p
              className="text-xs md:text-xl font-bold line-clamp-1 cursor-pointer"
              onClick={() => navigate("/thread/" + id)}
            >
              {title}
            </p>
            <p className="text-[8px] md:text-xs text-gray-600 md:ml-5">
              {moment(createdAt).format("MMM Do, YYYY hh:mm a")}
            </p>
          </div>
          <Button
            onClick={handleClick}
            style={{
              border: 0,
              marginLeft: 5,
              marginTop: -5,
            }}
            icon={<MoreOutlined className="text-[20px] md:text-[24px]" />}
          />
        </div>
        <Divider className="m-[8px]" />
        <p className="text-xs md:text-sm line-clamp-3">{description}</p>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          Rename
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          Share
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </div>
  );
}
