import { Layout } from "antd";
import { PropsWithChildren, useState } from "react";
import { CommentOutlined } from "@ant-design/icons";
import ProfileFloatingButton from "./profile-floating-button";
import { useMobile } from "src/helpers/helpers";
import SideNav from "./side-nav";
import MobileHeader from "./mobile-header";
import MobileSideNav from "./mobile-side-nav";

const links = [
  {
    path: "/threads",
    icon: <CommentOutlined style={{ fontSize: 23, marginRight: 4 }} />,
    name: "Threads",
  },
];

export default function SideNavLayout({ children }: PropsWithChildren) {
  const mobile = useMobile();
  const [drawerOpen, setDrawerOpen] = useState(false);

  return (
    <Layout hasSider={!mobile} className={"flex"}>
      <div className="max-md:hidden">
        <SideNav links={links} mobile={mobile} />
      </div>
      {mobile && (
        <MobileHeader
          visible={drawerOpen}
          setVisible={() => setDrawerOpen(!drawerOpen)}
        />
      )}
      {mobile && (
        <MobileSideNav
          links={links}
          visible={drawerOpen}
          setVisible={setDrawerOpen}
        />
      )}
      <div className={"flex-1 md:ml-[300px] bg-[#F6F6F6] min-h-screen"}>
        <ProfileFloatingButton />
        {children}
      </div>
    </Layout>
  );
}
