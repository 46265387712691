import { Button, Card, Input, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import * as EmailValidator from "email-validator";
import { useState } from "react";
import { Auth } from "aws-amplify";

export default function ResetPassword() {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const onReset = async () => {
    if (!EmailValidator.validate(email)) {
      return message.error("Invalid email.");
    }
    try {
      const data = await Auth.forgotPassword(email);
      message.success("Email sent!");
      navigate("/new-password", { state: { email: email } });
    } catch (err) {
      console.log(err);
      return message.error("Email not found.");
    }
  };

  return (
    <div className="bg-white">
      <div className="max-md:hidden w-9/12 bg-[#0AB1FF] h-screen flex flex-col items-center justify-center">
        <img src="/logo.png" alt="ArkeoAi" />
        <h1 className="text-3xl text-white max-w-md text-center">
          Building relationships between Ai and business!
        </h1>
      </div>
      <Card
        bordered={false}
        style={{
          top: 50,
          right: 20,
          bottom: 50,
          padding: 30,
          borderRadius: 20,
        }}
        className="flex justify-center md:w-[35%] md:absolute"
      >
        <p className="text-3xl font-normal text-center my-5">Reset Password</p>
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p className="text-gray-400 font-bold text-xs">Email</p>
          <p className="text-gray-500 font-light text-xs">Required</p>
        </div>
        <Input
          placeholder="Example@yourcompany.com"
          prefix={<MailOutlined className="mr-2" />}
          status={!EmailValidator.validate(email) ? "error" : undefined}
          onChange={({ target: { value } }) => setEmail(value)}
        />
        <Button
          className="w-full bg-[#e9eb49d9] font-bold text-md mt-5"
          onClick={onReset}
        >
          Reset
        </Button>
        <div className="flex flex-row items-center justify-center mt-10">
          <Button type="link" onClick={() => navigate("/")}>
            <p className="text-[#fe8761d9] underline -ml-2 text-sm">Sign In</p>
          </Button>
        </div>
      </Card>
    </div>
  );
}
