import { Button, ButtonProps } from "antd";
import ProfileCard from "./components/profile-card";
import ContextCard from "./components/context-card";
import InviteCard from "./components/invite-card";
import PasswordSecurityCard from "./components/password-security-card";
import { useState } from "react";

enum SettingsState {
  Profile,
  Security,
  Invite,
  Context,
}

export default function Settings() {
  const [currentState, setCurrentState] = useState(SettingsState.Profile);

  return (
    <div className="px-10 mt-[95px] flex flex-row">
      <div className="flex flex-col">
        <CustomButton
          text={"Profile"}
          onClick={() => setCurrentState(SettingsState.Profile)}
          selected={currentState === SettingsState.Profile}
        />
        <CustomButton
          text={"Password and Security"}
          onClick={() => setCurrentState(SettingsState.Security)}
          selected={currentState === SettingsState.Security}
        />
        <CustomButton
          text={"Invitations and Permissions"}
          onClick={() => setCurrentState(SettingsState.Invite)}
          selected={currentState === SettingsState.Invite}
        />
        <CustomButton
          text={"Context"}
          onClick={() => setCurrentState(SettingsState.Context)}
          selected={currentState === SettingsState.Context}
        />
      </div>
      <div className="flex flex-1 mt-5">
        {(() => {
          switch (currentState) {
            case SettingsState.Profile:
              return <ProfileCard />;
            case SettingsState.Security:
              return <PasswordSecurityCard />;
            case SettingsState.Invite:
              return <InviteCard />;
            case SettingsState.Context:
              return <ContextCard />;
            default:
              return null;
          }
        })()}
      </div>
    </div>
  );
}

const CustomButton = ({
  text,
  selected,
  ...props
}: ButtonProps & { selected: boolean; text: string }) => (
  <Button
    className={`w-[280px] h-[42px] bg-white mt-5 mr-5 text-[#222B45] font-sans pl-[20px] border-[0] text-start hover:shadow-lg ${
      selected ? "border-2 border-[#0AB1FF]" : ""
    }`}
    {...props}
  >
    <span className="text-[16px] font-[600] text-gray-600">{text}</span>
  </Button>
);
