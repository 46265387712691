import endpoint from "src/endpoint";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Message, User } from "../types";

const baseUrl = process.env.REACT_APP_API_URL;

const apiService = {
  login(email: string, password: string) {
    return endpoint()<{
      token: string;
      refreshToken: string;
    }>("post", "clients/process_login", {
      email,
      password,
    });
  },
  sendChatMessage,
  createThread,
  getThreads,
  getCurrentUserInfo,
  getThreadInfo,
  getUsers,
  sendInvite,
  deactivateUser,
  updateProfileDetails,
  getProfilePicturePresignedUrl,
};
export default apiService;

export async function getCurrentUserInfo() {
  const userId = await getCurrentUserId();
  const { data: user } = await endpoint()<User>("get", `user/${userId}`);
  return user || ({} as User);
}

async function getCurrentUserId() {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const userId = user.attributes.sub; // "sub" attribute is the user's unique ID
    return userId;
  } catch (error) {
    console.error("Error getting user: ", error);
    return null;
  }
}

async function updateProfileDetails(
  displayName: string,
  aboutMe: string,
  experience: string
) {
  const user = await getCurrentUserInfo();

  return endpoint()("post", `user/${user.id}/profile`, {
    displayName,
    aboutMe,
    experience,
  });
}

async function getProfilePicturePresignedUrl(
  fileName: string,
  fileType: string
) {
  const user = await getCurrentUserInfo();

  return endpoint()<{ uploadUrl: string }>(
    "post",
    `user/${user.id}/profile/picture`,
    {
      fileName,
      fileType,
    }
  );
}

export async function getIdToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error("Error getting ID token", error);
    return null;
  }
}

async function getThreads() {
  const user = await getCurrentUserInfo();
  return endpoint()<
    Array<{
      id: string;
      createdAt: string;
      title: string;
      messages: Array<Message>;
    }>
  >("get", `chat/${user.tenantId}/user/${user.id}/thread`);
}

async function getUsers() {
  const user = await getCurrentUserInfo();
  return endpoint()<User[]>("get", `tenant/${user.tenantId}/user`);
}

async function sendInvite(email: string, name: string) {
  const user = await getCurrentUserInfo();
  return endpoint()("post", `tenant/${user.tenantId}/user`, {
    email,
    name,
  });
}

async function deactivateUser(userId?: string) {
  return endpoint()("delete", `user/${userId}`);
}

async function getThreadInfo(threadId: string) {
  const user = await getCurrentUserInfo();
  return endpoint()<{ id: string; messages: Array<Message> }>(
    "get",
    `/chat/${user.tenantId}/user/${user.id}/thread/${threadId}`
  );
}

async function createThread(): Promise<string> {
  try {
    const user = await getCurrentUserInfo();
    const idToken = await getIdToken();

    if (!idToken) {
      console.error("No ID token available");
    }
    // create new thread
    const response = await axios.post(
      baseUrl + "/chat/" + user.tenantId + "/user/" + user.id + "/thread",
      {},
      {
        headers: {
          Authorization: idToken,
        },
      }
    );

    if (response.status !== 200 || !response.data) {
      console.log("Error sending chat message:", response);
    }

    return response.data.id;
  } catch (error) {
    if (error instanceof Error) {
      // Now TypeScript knows that 'error' is an instance of the Error class
      console.error("Error sending chat message:", error.message);
    } else {
      // Handle cases where the caught error might not be an instance of the Error class
      console.error("Error sending chat message:", error);
    }
    console.log("Error sending chat message:", error);
    return "";
  }
}

async function sendChatMessage(
  message: string,
  threadID: string
): Promise<string> {
  try {
    const user = await getCurrentUserInfo();
    const idToken = await getIdToken();

    if (!idToken) {
      console.error("No ID token available");
    }

    const response = await axios.post(
      baseUrl +
        "/chat/" +
        user.tenantId +
        "/user/" +
        user.id +
        "/thread/" +
        threadID,
      {
        userMessage: message,
      },
      {
        headers: {
          Authorization: idToken,
        },
      }
    );

    if (response.status !== 200 || !response.data) {
      console.log("Error sending chat message:", response);
    }

    return response.data.answer;
  } catch (error) {
    if (error instanceof Error) {
      // Now TypeScript knows that 'error' is an instance of the Error class
      console.error("Error sending chat message:", error.message);
    } else {
      // Handle cases where the caught error might not be an instance of the Error class
      console.error("Error sending chat message:", error);
    }
    console.log("Error sending chat message:", error);
    return "";
  }
}
