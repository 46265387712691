import { Routes, Route, Outlet } from "react-router-dom";
import SignIn from "./pages/sign-in";
import Dashboard from "./pages/dashboard";
import Threads from "./pages/threads";
import { Amplify } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import Settings from "./pages/settings";
import ThreadById from "./pages/thread-by-id";
import ResetPassword from "./pages/reset-password";
import NewPassword from "./pages/new-password";
import store from "./redux/store";
import { Provider } from "react-redux";
import SideNavLayout from "./pages/components/side-nav-layout";

const AppLayout = () => (
  <SideNavLayout>
    <Outlet />
  </SideNavLayout>
);

const amplifyConfig = {
  "aws_project_region": process.env.REACT_APP_COGNITO_REGION,
  "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  "aws_cognito_region": process.env.REACT_APP_COGNITO_REGION,
  "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOL_ID,
  "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_CLIENT_ID,
  "oauth": {},
  "aws_cognito_username_attributes": [
      "EMAIL",
      "PHONE_NUMBER"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
      "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
      "SMS"
  ],
  "aws_cognito_password_protection_settings": {
      "passwordPolicyMinLength": 8,
      "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
      "EMAIL"
  ]
}
  
Amplify.configure(amplifyConfig);

export function App() {
  const { authStatus } = useAuthenticator((context) => [context.user]);

  return (
    <Provider store={store}>
      <Routes>
        {authStatus === "authenticated" ? (
          <>
            <Route element={<AppLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="threads" element={<Threads />} />
              <Route path="settings" element={<Settings />} />
              <Route path="thread/:id" element={<ThreadById />} />
            </Route>
            <Route path="reset-password" element={<ResetPassword />} />
          </>
        ) : authStatus === "unauthenticated" ? (
          <>
            <Route index element={<SignIn />} />
            {/*<Route path="sign-up" element={<SignUp />} />*/}
            <Route path="reset-password" element={<ResetPassword />} />
            <Route path="new-password" element={<NewPassword />} />
          </>
        ) : null}
      </Routes>
    </Provider>
  );
}

export default App;
