import { Button, Layout } from "antd";
import { MenuOutlined } from "@mui/icons-material";

const { Header } = Layout;

interface Props {
  visible: boolean;
  setVisible: (visible: boolean) => void;
}

function MobileHeader(props: Props) {
  return (
    <Header style={{ paddingLeft: 20, backgroundColor: "white" }}>
      <Button onClick={() => props.setVisible(!props.visible)}>
        <MenuOutlined />
      </Button>
    </Header>
  );
}

export default MobileHeader;
