import { Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Lock } from "@mui/icons-material";

export default function PasswordSecurityCard() {
  return (
    <div className="p-5 flex flex-col bg-white rounded-[10px] flex-1">
      <div className="flex flex-row justify-between items-start">
        <div className="flex flex-row items-start">
          <Lock
            style={{
              width: 80,
              height: 80,
              backgroundColor: "#0AB1FF",
              borderRadius: 80,
              padding: 10,
              fill: "white",
              marginRight: 20,
            }}
          />
          <div className="flex flex-col">
            <p className="font-[700] text-[22px] text-gray-700">Password</p>
            <p className="text-gray-500">Shhh! it's a secret...</p>
          </div>
        </div>
        <Button
          className="font-bold text-md mr-2 text-gray-400 bg-gray-100"
          onClick={() => window.open("/reset-password", "_blank")}
        >
          RESET
        </Button>
      </div>
    </div>
  );
}
