import { createReducer } from "typesafe-actions";
import { setMobile } from "./actions";

const uiReducer = createReducer<{
  mobile: boolean;
}>({
  mobile: false,
}).handleAction(setMobile, (state, action) => ({
  ...state,
  mobile: action.payload,
}));

export default uiReducer;
