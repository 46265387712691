import Chat from "./components/chat";
import api from "../services/api-service";
import { useEffect, useState } from "react";
import { User } from "src/types";

export default function Dashboard() {
  const [user, setUser] = useState<User>();
  useEffect(() => {
    const res = api.getCurrentUserInfo();
    res.then((res) => {
      setUser(res);
    });
  }, []);

  return (
    <div className="p-[20px] md:p-10 min-h-screen flex flex-col justify-between items-center">
      <div className="w-full mt-10 mb-5">
        <p className="text-gray-500 font-bold text-xl text-center mb-5">
          Hi {user?.profile?.displayName || user?.name}, how can I help?
        </p>
        <Chat />
      </div>
      <p className="text-gray-500 mt-auto text-[10px] md:text-xs">
        ArkeoAi may produce inaccurate information about people, places, or
        facts. 2023
      </p>
    </div>
  );
}
