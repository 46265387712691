import { Avatar, Button, Input, Tooltip, Upload, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { InfoTwoTone } from "@mui/icons-material";
import { useEffect, useState } from "react";
import apiService from "src/services/api-service";
import { useUser } from "./hooks";

export default function ProfileCard() {
  const { data: user } = useUser();
  const [bio, setBio] = useState("");
  const [job, setJob] = useState("");
  const [name, setName] = useState("");
  const [profilePicture, setProfilePicture] = useState<string>();
  const [uploadLoading, setUploadingLoading] = useState(false);

  const updateProfilePicture = async (file: any) => {
    setUploadingLoading(true);
    const { data } = await apiService.getProfilePicturePresignedUrl(
      file.name,
      file.type
    );
    if (!data) {
      setUploadingLoading(false);
      return;
    }
    try {
      await fetch(data.uploadUrl, {
        method: "PUT",
        body: file,
      });
      const { profile } = await apiService.getCurrentUserInfo();
      setProfilePicture(profile?.profilePicture);
      message.success("Profile picture successfully updated");
      setUploadingLoading(false);
    } catch (e) {
      setUploadingLoading(false);
    }
  };

  const updateProfileDetails = async () => {
    await apiService.updateProfileDetails(name, bio, job);
  };

  useEffect(() => {
    if (user) {
      setName(user.profile?.displayName || user?.name || "");
      setBio(user.profile?.aboutMe || "");
      setJob(user.profile?.experience || "");
      setProfilePicture(
        user?.profile?.profilePicture || "./profile-default-picture.png"
      );
    }
  }, [user]);

  return (
    <div className="flex flex-col flex-1">
      <div className="p-5 flex flex-col bg-white rounded-[10px] flex-1">
        <div className="flex flex-row justify-between items-start">
          <div className="flex flex-row">
            <Avatar className="mr-8" src={profilePicture} size={150} />
            <p className="font-[700] text-[22px] text-gray-700">Photo</p>
          </div>
          <Upload
            accept={"image/*"}
            customRequest={({ file }) => updateProfilePicture(file)}
            showUploadList={false}
          >
            <Button
              className="font-bold text-md mr-2 text-gray-400 bg-gray-100"
              loading={uploadLoading}
            >
              UPDATE
            </Button>
          </Upload>
        </div>
      </div>
      <div className="mt-5 p-5 flex flex-col bg-white rounded-[10px] flex-1">
        <div className="flex flex-row justify-between">
          <p className="mb-5 font-[700] text-[28px] text-gray-700">
            Account Information:
          </p>
          <Tooltip title="This will never be sent to any AI or Language Model.">
            <InfoTwoTone
              style={{
                width: 35,
                height: 35,
                fill: "#0AB1FF",
                opacity: 0.8,
                cursor: "pointer",
              }}
            />
          </Tooltip>
        </div>
        <div className="w-fit">
          <p className="text-gray-700 text-sm mb-1">Display Name</p>
          <Input
            placeholder="First Name"
            onChange={({ target: { value } }) => setName(value)}
            value={name}
          />
          <p className="text-gray-700 text-md mt-5">Bio</p>
          <TextArea
            placeholder="Tell us a little about yourself!"
            autoSize={{ minRows: 5, maxRows: 5 }}
            className="flex mt-2 w-[500px] border-[#F6F6F6]"
            style={{
              boxShadow: "4px 4px 12px 0px #8F9BB314",
            }}
            onChange={({ target: { value } }) => setBio(value)}
            value={bio}
          />
          <p className="text-gray-700 text-md mt-5">
            Job Title and Job Description
          </p>
          <TextArea
            placeholder="What kind of tasks do you complete in this role?"
            autoSize={{ minRows: 5, maxRows: 5 }}
            className="flex mt-2 w-[500px] border-[#F6F6F6]"
            style={{
              boxShadow: "4px 4px 12px 0px #8F9BB314",
            }}
            onChange={({ target: { value } }) => setJob(value)}
            value={job}
          />
        </div>

        <Button
          className="font-bold text-md mr-2 text-gray-400 bg-gray-100 self-end"
          onClick={updateProfileDetails}
        >
          UPDATE
        </Button>
      </div>
    </div>
  );
}
