import { getCurrentUserInfo, getIdToken } from "./api-service";

export default class ArkeoAiChatService {
  webSocket!: WebSocket;

  connect = () => {
    this.webSocket = new WebSocket(
      process.env.REACT_APP_WEBSOCKET_API_URL || ""
    );

    this.webSocket.onopen = (event) => {
      console.log("WebSocket connection opened:", event);
    };

    this.webSocket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    this.webSocket.onclose = (event) => {
      console.log("WebSocket connection closed:", event);
    };
  };

  setWebSocketCallback = (callback: Function) => {
    this.webSocket.onmessage = (event) => {
      const response = JSON.parse(event.data);
      callback(response);
    };
  };

  sendChatMessage = async (message: string, threadID: string) => {
    const user = await getCurrentUserInfo();
    const idToken = await getIdToken();

    if (!idToken) {
      console.error("No ID token available");
    }

    const payload = {
      // sic: this is not a typo, the backend expects "sendMessgae"
      // TODO: fix typo in backend
      action: "sendMessgae",
      data: {
        userMessage: message,
        threadID: threadID,
        userID: user.id,
        tenantID: user.tenantId,
        type: "user",
      },
    };

    // reconnect if the websocket is closed
    if (this.webSocket.readyState !== this.webSocket.OPEN) {
      this.connect();
    }
    this.webSocket.send(JSON.stringify(payload));
  };
}
