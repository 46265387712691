import { Button, Card, Input, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CodeOutlined } from "@mui/icons-material";

export default function NewPassword() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const data = await Auth.forgotPasswordSubmit(email, code, password);
      console.log(data);
      navigate("/sign-in");
    } catch (e: any) {
      console.log(e);
      return message.error(
        e?.name?.includes("UserNotFoundException")
          ? "Email not found."
          : e?.name?.includes("CodeMismatchException")
          ? "Invalid verification code provided."
          : "Password length should be more than 8 characters."
      );
    }
    setLoading(false);
  };
  return (
    <div className="bg-white">
      <div className="w-9/12 bg-[#0AB1FF] h-screen flex flex-col items-center justify-center">
        <img src="/logo.png" alt="ArkeoAi" />
        <h1 className="text-3xl text-white max-w-md text-center">
          Building relationships between Ai and business!
        </h1>
      </div>
      <Card
        bordered={false}
        style={{
          position: "absolute",
          top: 50,
          right: 20,
          bottom: 50,
          padding: 30,
          width: "35%",
          borderRadius: 20,
        }}
      >
        <p className="text-3xl font-normal text-center my-5">Login to Arkeo</p>
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p className="text-gray-400 font-bold text-xs">Email</p>
          <p className="text-gray-500 font-light text-xs">Required</p>
        </div>
        <Input
          placeholder="Example@yourcompany.com"
          suffix={<MailOutlined />}
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p className="text-gray-400 font-bold text-xs">Password</p>
          <p className="text-gray-500 font-light text-xs">Required</p>
        </div>
        <Input.Password
          suffix={<LockOutlined />}
          placeholder="Password"
          onChange={(event) => setPassword(event.target.value)}
        />
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p className="text-gray-400 font-bold text-xs">Verification Code</p>
          <p className="text-gray-500 font-light text-xs">Required</p>
        </div>
        <Input
          suffix={<CodeOutlined />}
          value={code}
          onChange={(event) => setCode(event.target.value)}
          placeholder="Verification Code"
        />
        <Button
          className="w-full bg-[#e9eb49d9] font-bold text-md mt-10"
          onClick={onSubmit}
          loading={loading}
        >
          Change Password
        </Button>
        <div className="flex flex-row items-center justify-center mt-5">
          <p className="text-gray-500 text-sm">Forgot Password? </p>
          <Button type="link" onClick={() => navigate("reset-password")}>
            <p className="text-[#fe8761d9] underline -ml-2 text-sm">Reset</p>
          </Button>
        </div>
        {/* 
        <div className="flex flex-row items-center justify-center mt-2">
          <Button type="link" onClick={() => navigate("sign-up")}>
            <p className="text-[#fe8761d9] underline -ml-2 text-sm">Sign Up</p>
          </Button>
        </div>*/}
      </Card>
    </div>
  );
}
