import { Button, Card, Input, message } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordChange, setPasswordChange] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [user, setUser] = useState();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onLogin = async () => {
    setLoading(true);

    try {
      const data = await Auth.signIn(email, password);
      if (data?.challengeName === "NEW_PASSWORD_REQUIRED") {
        setUser(data);
        message.warning("You have to change your password.");
        setPasswordChange(true);
      }
    } catch (error) {
      message.error("Invalid credentials.");
    }
    setLoading(false);
  };

  const resetPassword = async () => {
    if (newPassword === password) {
      message.error("Your new password must be different");
    }
    setLoading(true);

    try {
      await Auth.completeNewPassword(user, newPassword);
    } catch (error) {
      message.error("Invalid credentials.");
    }

    setLoading(false);
  };

  return (
    <div className="bg-white">
      <div className="max-md:hidden w-9/12 bg-[#0AB1FF] h-screen flex flex-col items-center justify-center">
        <img src="/logo.png" alt="ArkeoAi" />
        <h1 className="text-3xl text-white max-w-md text-center">
          Building relationships between Ai and business!
        </h1>
      </div>
      <Card
        bordered={false}
        style={{
          top: 50,
          right: 20,
          bottom: 50,
          padding: 30,
          borderRadius: 20,
          overflow: "scroll",
        }}
        className="flex justify-center md:w-[35%] md:absolute"
      >
        <p className="text-3xl font-normal text-center my-5">Login to Arkeo</p>
        {!passwordChange && (
          <>
            <div className="flex flex-row justify-between mt-5 mb-2">
              <p className="text-gray-400 font-bold text-xs">Email</p>
              <p className="text-gray-500 font-light text-xs">Required</p>
            </div>
            <Input
              placeholder="Example@yourcompany.com"
              suffix={<MailOutlined />}
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </>
        )}
        <div className="flex flex-row justify-between mt-5 mb-2">
          <p className="text-gray-400 font-bold text-xs">
            {passwordChange ? "New Password" : "Password"}
          </p>
          <p className="text-gray-500 font-light text-xs">Required</p>
        </div>
        <Input.Password
          suffix={<LockOutlined />}
          placeholder={passwordChange ? "New Password" : "Password"}
          onChange={(event) =>
            passwordChange
              ? setNewPassword(event.target.value)
              : setPassword(event.target.value)
          }
          value={passwordChange ? newPassword : password}
        />
        <Button
          className="w-full bg-[#e9eb49d9] font-bold text-md mt-10"
          onClick={passwordChange ? resetPassword : onLogin}
          loading={loading}
        >
          {passwordChange ? "Change Password" : "Login"}
        </Button>
        <div className="flex flex-row items-center justify-center mt-5">
          <p className="text-gray-500 text-sm">Forgot Password? </p>
          <Button type="link" onClick={() => navigate("reset-password")}>
            <p className="text-[#fe8761d9] underline -ml-2 text-sm">Reset</p>
          </Button>
        </div>
        {/* 
        <div className="flex flex-row items-center justify-center mt-2">
          <Button type="link" onClick={() => navigate("sign-up")}>
            <p className="text-[#fe8761d9] underline -ml-2 text-sm">Sign Up</p>
          </Button>
        </div>*/}
      </Card>
    </div>
  );
}
